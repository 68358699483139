var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pr-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("change-tab", 0)
                        },
                      },
                    },
                    [_vm._v(" vip 專屬 ")]
                  ),
                  _c(
                    "swiper",
                    {
                      ref: "vipTab",
                      staticClass: "swiper",
                      attrs: { options: _vm.vipTabsOption },
                    },
                    _vm._l(_vm.showVipItem, function (vip, index) {
                      return _c(
                        "swiper-slide",
                        {
                          key: vip._id,
                          staticClass: "rounded vip-tab",
                          class: [
                            _vm.activeIndex === index
                              ? "vipCard1 white--text"
                              : "inputBg subtitle--text",
                          ],
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-100 d-flex align-center justify-center cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.setVipSwiperActive(index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(vip.name) + " ")]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                  _vm._v(" 4 "),
                  _c(
                    "swiper",
                    {
                      ref: "vipCard",
                      staticClass: "swiper swiper-no-swiping mt-3",
                      attrs: { options: _vm.vipCardsOption },
                    },
                    _vm._l(_vm.showVipItem, function (vip, index) {
                      return _c(
                        "swiper-slide",
                        {
                          key: vip._id,
                          staticClass: "rounded vip-card",
                          class: [
                            index < 7 - 1 ? "vipCard1" : "vipCard2",
                            {
                              vipCard3: index > 8 - 1,
                            },
                          ],
                        },
                        [
                          _c("div", [
                            _c("img", {
                              staticClass: "p-absolute vip-card__icon",
                              attrs: {
                                src: _vm.baseImgUrl + vip.icon,
                                width: "36",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "p-absolute font-weight-bold vip-card__title card1--text",
                              },
                              [_vm._v(" " + _vm._s(vip.name) + " ")]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "p-absolute vip-card__description w-100 white--text",
                            },
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "2" } }),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-left caption px-0 py-0",
                                          attrs: { cols: "5" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "myCenter.accumulatedDeposit"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-right subtitle-2 font-weight-medium px-0 py-0",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(vip.deposit_amount) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "2" } }),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-left caption px-0 py-0",
                                          attrs: { cols: "5" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "myCenter.turnoverRequire"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-right subtitle-2 font-weight-medium px-0 py-0",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(vip.bet_amount) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "2" } }),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-left caption px-0 py-0",
                                          attrs: { cols: "5" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "myCenter.threeMonthsForRelegationFlow"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-right subtitle-2 font-weight-medium px-0 py-0",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(vip.min_bet_amount) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "bonus px-3 pb-3" },
            [
              _c(
                "v-col",
                {
                  staticClass: "px-1 pt-1",
                  attrs: { cols: _vm.activeIndex > 3 ? 4 : 6 },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card1 bonus__item rounded d-flex flex-column justify-center align-center text-center px-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "caption title--text font-weight-medium",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("vip.upgradeBonus")) + " ")]
                      ),
                      _vm.activeVipData
                        ? _c(
                            "div",
                            { staticClass: "text-h6 secondary--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.activeVipData.upgrade_bonus) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "px-1 pt-1",
                  attrs: { cols: _vm.activeIndex > 3 ? 4 : 6 },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card1 bonus__item rounded d-flex flex-column justify-center align-center text-center px-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "caption title--text font-weight-medium",
                          class: { "pt-4": _vm.btnStatus.redpacket },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("vip.monthlyDiscountRedEnvelope")) +
                              " "
                          ),
                        ]
                      ),
                      _vm.activeVipData
                        ? _c(
                            "div",
                            { staticClass: "text-h6 secondary--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.activeVipData.monthly_bonus) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.btnStatus.redpacket ? _c("v-spacer") : _vm._e(),
                      _vm.btnStatus.redpacket
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "bonus__item-btn primary w-100 rounded-b",
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "btnText--text caption font-weight-medium",
                                  attrs: { text: "", block: "", height: "24" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getVipBonus(
                                        "redpacket",
                                        _vm.activeIndex + 1
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("vip.getIt")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeIndex > 3,
                      expression: "activeIndex > 3",
                    },
                  ],
                  staticClass: "px-1 pt-1",
                  attrs: { cols: "4" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card1 bonus__item rounded d-flex flex-column justify-center align-center text-center px-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "caption title--text font-weight-medium",
                          class: { "pt-4": _vm.btnStatus.redpacket },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("myCenter.birthdayBonus")) + " "
                          ),
                        ]
                      ),
                      _vm.activeVipData
                        ? _c(
                            "div",
                            { staticClass: "text-h6 secondary--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.activeVipData.birth_bonus) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.btnStatus.redpacket ? _c("v-spacer") : _vm._e(),
                      _vm.btnStatus.birthday
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "bonus__item-btn primary w-100 rounded-b",
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "btnText--text caption font-weight-medium",
                                  attrs: { text: "", block: "", height: "24" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getVipBonus(
                                        "birthday",
                                        _vm.activeIndex + 1
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("vip.getIt")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeVipData.extra_bonus,
                  expression: "activeVipData.extra_bonus",
                },
              ],
              staticClass: "mt-0",
            },
            [
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "divider" })],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex align-center subtitle-1 title-text font-weight-medium",
                  attrs: { cols: "3" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("vip.prize")) + " ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center secondary--text",
                  attrs: { cols: "9" },
                },
                [
                  _c("div", { staticClass: "caption font-weight-medium" }, [
                    _vm._v(" " + _vm._s(_vm.activeVipData.extra_bonus) + " "),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.btnStatus.bonus_extra,
                          expression: "btnStatus.bonus_extra",
                        },
                      ],
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "ml-6 btnText--text caption font-weight-medium",
                          attrs: {
                            "min-width": "52",
                            height: "28",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getVipExtra(_vm.activeIndex + 1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("global.application")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.activeVipData && false
            ? _c(
                "v-row",
                { staticClass: "bonus-2 px-3" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "subtitle-1 title-text font-weight-medium px-1 pt-0",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("v-divider", { staticClass: "mb-2 divider" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.activeVipData.name +
                              " " +
                              _vm.$t("vip.discountInfo")
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "px-1 py-1 caption", attrs: { cols: "6" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card1 rounded d-flex flex-column align-center justify-center bonus-2__item",
                        },
                        [
                          _c("div", { staticClass: "bonus__times" }, [
                            _c(
                              "span",
                              { staticClass: "primary--text text-h6" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.activeVipData.rebate_min_transfer
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "bonus__description titile--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("vip.minimumTransfer")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "px-1 py-1 caption", attrs: { cols: "6" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card1 rounded d-flex flex-column align-center justify-center bonus-2__item",
                        },
                        [
                          _c("div", { staticClass: "bonus__times" }, [
                            _c(
                              "span",
                              { staticClass: "primary--text text-h6" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.activeVipData.rebate_bonus_percent
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "bonus__description titile--text" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("vip.dividends")) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "px-1 py-1 caption", attrs: { cols: "4" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card1 rounded d-flex flex-column align-center justify-center bonus-2__item text-center",
                        },
                        [
                          _c("div", { staticClass: "bonus__times" }, [
                            _c(
                              "span",
                              { staticClass: "primary--text text-h6" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.activeVipData.rebate_max) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "bonus__description titile--text" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("vip.maximunBouns")) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "px-1 py-1 caption", attrs: { cols: "4" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card1 rounded d-flex flex-column align-center justify-center bonus-2__item text-center",
                        },
                        [
                          _c("div", { staticClass: "bonus__times" }, [
                            _c(
                              "span",
                              { staticClass: "primary--text text-h6" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        `global['${_vm.activeVipData.rebate_frequency}']`
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "bonus__description titile--text" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("vip.timesLimit")) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "px-1 py-1 caption", attrs: { cols: "4" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card1 rounded d-flex flex-column align-center justify-center bonus-2__item text-center",
                        },
                        [
                          _c("div", { staticClass: "bonus__times" }, [
                            _c(
                              "span",
                              { staticClass: "primary--text text-h6" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.activeVipData.rebate_multiple) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "bonus__description titile--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("vip.turnoverMultiple")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "px-0 pb-0", attrs: { cols: "12" } },
                    [_c("v-divider", { staticClass: "divider" })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "px-1" },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "subtitle-1 title-text font-weight-medium px-3 pt-2",
                  attrs: { cols: "12" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("myCenter.vipTurnover")) + " ")]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.activeVipData
                    ? _c(
                        "v-simple-table",
                        { staticClass: "card1", attrs: { dense: "" } },
                        [
                          _c("thead", { staticClass: "game" }, [
                            _c(
                              "tr",
                              [
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-1 py-1 text-center btnText--text primary rounded-tl w-41",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("global.level")) + " "
                                    ),
                                  ]
                                ),
                                _vm._l(
                                  _vm.activeVipData.rebates,
                                  function (game) {
                                    return _c(
                                      "th",
                                      {
                                        key: game.name,
                                        staticClass:
                                          "px-1 text-center title--text game__name",
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "bacground primary w-100 h-100",
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "p-relative" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(game.name) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.vipList, function (item) {
                              return _c(
                                "tr",
                                { key: item._id },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "caption secondary--text px-2 text-center",
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  ),
                                  _vm._l(item.rebates, function (game) {
                                    return _c(
                                      "td",
                                      {
                                        key: game.name,
                                        staticClass:
                                          "text-center caption title--text px-1",
                                      },
                                      [_vm._v(" " + _vm._s(game.value) + "% ")]
                                    )
                                  }),
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-1" },
            [
              _c(
                "v-col",
                {
                  staticClass: "subtitle-1 title-text font-weight-medium",
                  attrs: { cols: "12" },
                },
                [
                  _c("v-divider", { staticClass: "mb-2 divider" }),
                  _vm._v(" " + _vm._s(_vm.$t("vip.vipWithdrawLimit")) + " "),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.activeVipData
                    ? _c(
                        "v-simple-table",
                        { staticClass: "card1", attrs: { dense: "" } },
                        [
                          _c("thead", { staticClass: "withdrawals" }, [
                            _c(
                              "tr",
                              [
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "px-1 py-1 text-center btnText--text primary rounded-tl w-41",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("global.level")) + " "
                                    ),
                                  ]
                                ),
                                _vm._l(
                                  _vm.withdrawalsTableHead,
                                  function (text, index) {
                                    return _c(
                                      "th",
                                      {
                                        key: index,
                                        staticClass:
                                          "px-1 text-center title--text withdrawals__name",
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "bacground primary w-100 h-100",
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "p-relative" },
                                          [_vm._v(" " + _vm._s(text) + " ")]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.vipList, function (item) {
                              return _c("tr", { key: item._id }, [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "caption secondary--text px-2 text-center",
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "text-center caption title--text px-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.withdrawal_daily_count) +
                                        " "
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("myCenter.times"))),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "text-center caption title--text px-1",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.withdrawal_daily_amount) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        { attrs: { dialog: _vm.dialog.status, width: 280, height: 140 } },
        [
          _c(
            "div",
            { staticClass: "px-6", attrs: { slot: "text" }, slot: "text" },
            [_vm._v(" " + _vm._s(_vm.dialog.text) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", color: "primary", text: "" },
              on: { click: _vm.closeDialog },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }