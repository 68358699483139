
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { clickEventInterval } from '@/utils/event'

import Dialog from '@/components/dialog'

export default {
  components: { Dialog },

  props: {
    vipList: {
      type: Array,
      required: true,
    },
  },

  data: () => ({

    activeIndex: 0,

    vipTabsOption: {
      slidesPerView: 5.5,
      spaceBetween: 8,
      centeredSlides: true,
      centeredSlidesBounds: true,
    },

    vipCardsOption: {
      slidesPerView: 'auto',
      spaceBetween: 8,
    },

    /*
     * bonus button status
     * redpacket 每月红包 - "Y":顯示 - "N":不顯示
     * birthday 生日礼金 - "Y":顯示 - "N":不顯示
     * bonus_extra 豪礼赠送 - "Y":顯示 - "N":不顯示
     */
    btnStatus: [],

    dialog: {
      status: false,
      text: '',
    },

    // 執行過後，是否可以再打 api
    eventLaunch: {
      getVipBonus: null,
      getVipExtra: null,
    },
  }),

  computed: {
    ...mapState({
      nowVip: state => state.vip.now,
      settings: state => state.websiteData.settings,
    }),

    swiperOption() {
      return {
        slidesPerView: 1.2,
        centeredSlides: true,
        spaceBetween: 10,
      }
    },

    ...mapGetters(['baseImgUrl', 'layout', 'theme']),

    vipCardsChange(e) {
      console.log('vipCardsChange', e)
    },

    // 不 show 新進會員
    showVipItem() {
      return this.vipList.slice(1)
    },

    defaultActiveVipIndex() {
      const index =
          this.vipList.indexOf(
            this.vipList.filter(item => item.name === this.nowVip.name)[0],
          ) - 1

      return index === -1 ? 0 : index
    },

    activeVipData() {
      return this.vipList[this.activeIndex + 1]
    },

    /**
       * 提款額度 table thead (除了等級)
       * @date 2021-04-19
       */
    withdrawalsTableHead() {
      return [
        this.$t('myCenter.withdrawalDailyCount'), // 提款次數
        this.$t('myCenter.withdrawalDailyLimit'), // 提款額度
      ]
    },
  },

  mounted() {
    this.setVipSwiperActive(this.defaultActiveVipIndex)

    if (this.$refs.vipCards) {
      console.log(this.$refs.vipCards.$swiper.on('slideChangeTransitionEnd', (e) => {
        this.activeIndex = e.activeIndex
      }))
    }

    // 初始化 延遲打 api
    Object.keys(this.eventLaunch)
      .forEach(index => {
        this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
      })
  },

  methods: {
    ...mapActions([
      'check_user_vip_bonus_btn_status',
      'get_vip_bonus',
      'show_alert',
      'get_vip_extra',
    ]),

    /**
     * 設置要顯示的 vip for tabs vip cards
     * @date 2021-04-19
     * @param {any} index 0 => vip1
     */
    setVipSwiperActive(index) {
      this.$refs.vipCard && this.$refs.vipCard.$swiper.slideTo(index, 500)
      this.$refs.vipTab && this.$refs.vipTab.$swiper.slideTo(index, 500)

      // template3 Swiper
      this.$refs.vipCards && this.$refs.vipCards.$swiper.slideTo(index, 500)

      this.activeIndex = index
      this.checkUserBonusBtnStatus()
    },

    vipBackground(activeIndex) {
      const lightLv = [
        'rgba(0, 0, 0, 0.4)',
        'rgba(0, 0, 0, 0.6)',
        'rgba(0, 0, 0, 0.8)',
      ]

      const darkLv = [
        'rgba(255,255,255,.5)',
        'rgba(255,255,255,.12)',
        '#212121',
      ]

      if (this.theme === 'dark') {
        if (activeIndex < 7) {
          return darkLv[0]
        }
        if (activeIndex > 8) {
          return darkLv[2]
        }
        return darkLv[1]
      }

      if (activeIndex < 7) {
        return lightLv[0]
      }
      if (activeIndex > 8) {
        return lightLv[2]
      }
      return lightLv[1]
    },

    /**
     * 確認使用者 vip 優惠按鈕狀態 (成功)
     * 狀態 'Y' => true && 'N' => false
     * @date 2021-04-21
     * @param {any} {button}
     * @returns {any}
     */
    checkUserBonusBtnStatusSuccess({ button }) {
      const output = {}
      Object.keys(button).forEach(btnName => {
        output[btnName] = button[btnName] === 'Y'
      })
      this.btnStatus = output
    },

    /**
     * 確認使用者 vip 優惠按鈕狀態
     * @date 2021-04-20
     * @returns {object} 按鈕狀態
     */
    async checkUserBonusBtnStatus() {
      const activeVipLevel = { vip_level: this.activeVipData.code }
      const res = await this.check_user_vip_bonus_btn_status(activeVipLevel)
      resStatus(res, this.checkUserBonusBtnStatusSuccess)
    },

    /**
     * 領取 vip 優惠 (成功)
     * - 開啟彈窗
     * - 刷新按鈕狀態
     * @date 2021-04-20
     * @param {string} {sub_type} 優惠類型
     */
    getVipBonusSuccess({ sub_type }) {
      // this.dialog.text = this.$t('vip.gotVipBonus', {
      //   bonus: this.$t(`vip.${sub_type}`),
      // })
      // this.dialog.status = true
      this.show_alert({
        icon: 'done',
      })
      this.checkUserBonusBtnStatus()
    },

    /**
     * 領取 vip 優惠 (失敗)
     * @date 2021-04-20
     */
    getVipBonusFail() {
      this.show_alert({
        icon: 'fail',
      })
    },

    /**
     * 領取 vip 優惠 (紅包/生日)
     * @date 2021-04-20
     * @param {string} subtype 優惠類型
     * @param {string} vipLevel 等級
     */
    async getVipBonus(subtype, vipLevel) {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.getVipBonus.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.getVipBonus.setStatus(false)

      const formData = {
        sub_type: subtype,
        vip_level: vipLevel,
      }

      const res = await this.get_vip_bonus(formData)
      resStatus(res, this.getVipBonusSuccess, this.getVipBonusFail)
    },

    async getVipExtra(vipLevel) {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.getVipExtra.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.getVipExtra.setStatus(false)

      const formData = {
        vip_level: vipLevel,
      }

      const res = await this.get_vip_extra(formData)
      resStatus(res, this.getVipBonusSuccess, this.getVipBonusFail)
    },

    closeDialog() {
      this.dialog.status = false
      this.dialog.text = ''
    },
  },
}
