
import { mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapGetters(['baseImgUrl', 'layout', 'theme', 'currencySymbol_']),

    ...mapState({
      nowVip: state => state.vip.now,
      nextVip: state => state.vip.next,
      vipProgress: state => state.vip.progress,
    }),
  },
}
