var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "rounded ma-0 card2 mx-1" },
            [
              _c(
                "v-col",
                { staticClass: "px-0 pb-0 pt-0", attrs: { cols: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex px-4" },
                    [
                      _c(
                        "div",
                        { staticClass: "font-weight-bold title--text d-flex" },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.baseImgUrl + _vm.nowVip.icon,
                              width: "36",
                              height: "56",
                            },
                          }),
                          _c("div", { staticClass: "mt-3 ml-2" }, [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("vip.currentLevel")) + " "
                              ),
                            ]),
                            _c("span", { staticClass: "primary--text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.nowVip.code &&
                                    _vm.nowVip.code.toUpperCase()
                                )
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c("v-spacer"),
                      _c(
                        "div",
                        { staticClass: "d-flex align-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color:
                                  _vm.theme === "light"
                                    ? "primary"
                                    : "secondary",
                                outlined: _vm.theme === "dark" ? true : null,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("change-tab", 1)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("vip.details")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-col", { staticClass: "pt-0", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-center caption title--text font-weight-medium w-100 vip-progress",
                    staticStyle: { "margin-bottom": "10px" },
                  },
                  [_vm._v(" " + _vm._s(_vm.vipProgress.progress) + "% ")]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "userInfo__progress vip d-flex align-center py-0",
                  },
                  [
                    _c("span", { staticClass: "primary--text font-italic" }, [
                      _vm._v(
                        _vm._s(_vm.nowVip.code && _vm.nowVip.code.toUpperCase())
                      ),
                    ]),
                    _c("v-progress-linear", {
                      staticClass: "mx-2 vip__progress",
                      attrs: {
                        rounded: "",
                        value: _vm.vipProgress.progress,
                        color: "primary",
                        height: "6",
                      },
                    }),
                    _vm.nextVip
                      ? _c(
                          "span",
                          { staticClass: "primary--text font-italic" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.nextVip.code &&
                                  _vm.nextVip.code.toUpperCase()
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm.nextVip
                ? _c(
                    "v-col",
                    {
                      staticClass: "text-left subtitle-2 pt-0 caption px-6",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("ul", { staticClass: "pl-0 title--text" }, [
                        _c("li", [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("div", { staticClass: "title--text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("myCenter.nowDeposit")) +
                                    " " +
                                    _vm._s(`(${_vm.currencySymbol_})`) +
                                    " "
                                ),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "primary--text subtitle-1 font-weight-bold",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.vipProgress.deposit_amount) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "hint--text text-right" }, [
                            _vm._v(
                              " (" +
                                _vm._s(_vm.vipProgress.deposit_amount) +
                                " / " +
                                _vm._s(_vm.nextVip.deposit_amount) +
                                " ) "
                            ),
                          ]),
                        ]),
                        _c("li", [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("div", { staticClass: "title--text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("myCenter.nowTurnover")) +
                                    " " +
                                    _vm._s(`(${_vm.currencySymbol_})`) +
                                    " "
                                ),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "subtitle-1 primary--text font-weight-medium",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.vipProgress.bet_amount) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "hint--text text-right" }, [
                            _vm._v(
                              " (" +
                                _vm._s(_vm.vipProgress.bet_amount) +
                                " / " +
                                _vm._s(_vm.nextVip.bet_amount) +
                                " ) "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-3 bonus mt-2" },
            [
              _c(
                "v-col",
                { staticClass: "px-1 py-1 caption", attrs: { cols: "6" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card2 rounded text-center d-flex flex-column align-center justify-center h-80px px-2",
                    },
                    [
                      _c("div", { staticClass: "bonus__times" }, [
                        _c("span", { staticClass: "title--text text-h6" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.nowVip.withdrawal_daily_count) +
                              " "
                          ),
                        ]),
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("myCenter.times"))),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bonus__description titile--text" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("myCenter.withdrawalDailyCount")) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-1 py-1 caption", attrs: { cols: "6" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card2 rounded text-center d-flex flex-column align-center justify-center h-80px px-2",
                    },
                    [
                      _c("div", { staticClass: "bonus__times" }, [
                        _c("span", { staticClass: "title--text text-h6" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.nowVip.withdrawal_daily_amount) +
                              " "
                          ),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(_vm.currencySymbol_))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bonus__description titile--text" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("myCenter.withdrawalDailyLimit")) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-1 py-1 caption", attrs: { cols: "4" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card2 rounded text-center d-flex flex-column align-center justify-center h-80px px-2",
                    },
                    [
                      _c("div", { staticClass: "bonus__times" }, [
                        _c("span", { staticClass: "title--text text-h6" }, [
                          _vm._v(" " + _vm._s(_vm.nowVip.upgrade_bonus) + " "),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(_vm.currencySymbol_))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bonus__description titile--text" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link--text",
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("change-tab", 1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("myCenter.upgradeBonus")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-1 py-1 caption", attrs: { cols: "4" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card2 rounded text-center d-flex flex-column align-center justify-center h-80px px-2",
                    },
                    [
                      _c("div", { staticClass: "bonus__times" }, [
                        _c("span", { staticClass: "title--text text-h6" }, [
                          _vm._v(" " + _vm._s(_vm.nowVip.birth_bonus) + " "),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(_vm.currencySymbol_))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bonus__description titile--text" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link--text",
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("change-tab", 1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("myCenter.birthdayBonus")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-1 py-1 caption", attrs: { cols: "4" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card2 rounded text-center d-flex flex-column align-center justify-center h-80px px-2",
                    },
                    [
                      _c("div", { staticClass: "bonus__times" }, [
                        _c("span", { staticClass: "title--text text-h6" }, [
                          _vm._v(" " + _vm._s(_vm.nowVip.monthly_bonus) + " "),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(_vm.currencySymbol_))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bonus__description titile--text" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link--text",
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("change-tab", 1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("myCenter.monthlyBonus")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "rebate-ratio px-4 py-1 bg--text font-weight-bold mt-6",
        },
        [_vm._v(" " + _vm._s(_vm.$t("myCenter.vipTurnover")) + " ")]
      ),
      _c(
        "v-container",
        { staticClass: "mb-3" },
        [
          _c(
            "v-row",
            { staticClass: "px-4 mt-0 card1 mx-1 rounded" },
            _vm._l(_vm.nowVip.rebates, function (game) {
              return _c(
                "v-col",
                {
                  key: game.name,
                  staticClass: "caption",
                  attrs: { cols: "4" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded d-flex flex-column align-center justify-center h-80px",
                    },
                    [
                      _c("div", { staticClass: "rebates__percentage" }, [
                        _c("span", { staticClass: "title--text text-h6" }, [
                          _vm._v(" " + _vm._s(game.value) + "% "),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "rebates__description subtitle--text" },
                        [_vm._v(" " + _vm._s(game.name) + " ")]
                      ),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }