var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-btn",
            {
              attrs: { color: "success" },
              on: {
                click: function ($event) {
                  return _vm.$emit("change-tab", 1)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("vip.details")) + " ")]
          ),
          _c(
            "div",
            {
              staticClass: "text-center caption title--text font-weight-medium",
              staticStyle: { "margin-bottom": "10px" },
            },
            [_vm._v(" " + _vm._s(_vm.vipProgress.progress) + "% ")]
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "userInfo__progress vip d-flex align-center py-0 px-10",
                  class: [{ "pb-4": !_vm.nextVip }],
                  attrs: { cols: "12" },
                },
                [
                  _vm.nowVip.logo
                    ? _c("v-img", {
                        staticClass: "vip__now",
                        attrs: {
                          src: _vm.baseImgUrl + _vm.nowVip.logo,
                          "max-height": "28",
                          "max-width": "28",
                        },
                      })
                    : _vm._e(),
                  _c("v-progress-linear", {
                    staticClass: "mx-1 vip__progress",
                    attrs: {
                      rounded: "",
                      value: _vm.vipProgress.progress,
                      color: "primary",
                      height: "6",
                    },
                  }),
                  _vm._v(" 4 "),
                  _vm.nextVip
                    ? _c("v-img", {
                        staticClass: "vip__next",
                        attrs: {
                          src: _vm.baseImgUrl + _vm.nextVip.logo,
                          "max-height": "28",
                          "max-width": "28",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.nextVip
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12 text-left pb-4 subtitle-2 px-10 pt-0 caption",
                      },
                    },
                    [
                      _c("ul", { staticClass: "pl-0" }, [
                        _c("li", [
                          _c("span", { staticClass: "title--text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("myCenter.nowDeposit")) +
                                " " +
                                _vm._s(`(${_vm.currencySymbol_})`) +
                                " "
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "subtitle-1 primary--text font-weight-medium",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.vipProgress.deposit_amount) +
                                  " "
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "hint--text" }, [
                            _vm._v(
                              " (" +
                                _vm._s(_vm.vipProgress.deposit_amount) +
                                " / " +
                                _vm._s(_vm.nextVip.deposit_amount) +
                                " ) "
                            ),
                          ]),
                        ]),
                        _c("li", [
                          _c("span", { staticClass: "title--text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("myCenter.nowTurnover")) +
                                " " +
                                _vm._s(`(${_vm.currencySymbol_})`) +
                                " "
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "subtitle-1 primary--text font-weight-medium",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.vipProgress.bet_amount) + " "
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "hint--text" }, [
                            _vm._v(
                              " (" +
                                _vm._s(_vm.vipProgress.bet_amount) +
                                " / " +
                                _vm._s(_vm.nextVip.bet_amount) +
                                " ) "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-4 bonus" },
            [
              _c(
                "v-col",
                { staticClass: "px-1 py-1 caption", attrs: { cols: "6" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card1 rounded text-center d-flex flex-column align-center justify-center h-80px px-2",
                    },
                    [
                      _c("div", { staticClass: "bonus__times" }, [
                        _c("span", { staticClass: "secondary--text text-h6" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.nowVip.withdrawal_daily_count) +
                              " "
                          ),
                        ]),
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("myCenter.times"))),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bonus__description titile--text" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("myCenter.withdrawalDailyCount")) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-1 py-1 caption", attrs: { cols: "6" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card1 rounded text-center d-flex flex-column align-center justify-center h-80px px-2",
                    },
                    [
                      _c("div", { staticClass: "bonus__times" }, [
                        _c("span", { staticClass: "secondary--text text-h6" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.nowVip.withdrawal_daily_amount) +
                              " "
                          ),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(_vm.currencySymbol_))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bonus__description titile--text" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("myCenter.withdrawalDailyLimit")) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-1 py-1 caption", attrs: { cols: "4" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card1 rounded text-center d-flex flex-column align-center justify-center h-80px px-2",
                    },
                    [
                      _c("div", { staticClass: "bonus__times" }, [
                        _c("span", { staticClass: "secondary--text text-h6" }, [
                          _vm._v(" " + _vm._s(_vm.nowVip.upgrade_bonus) + " "),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(_vm.currencySymbol_))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bonus__description titile--text" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link--text",
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("change-tab", 1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("myCenter.upgradeBonus")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-1 py-1 caption", attrs: { cols: "4" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card1 rounded text-center d-flex flex-column align-center justify-center h-80px px-2",
                    },
                    [
                      _c("div", { staticClass: "bonus__times" }, [
                        _c("span", { staticClass: "secondary--text text-h6" }, [
                          _vm._v(" " + _vm._s(_vm.nowVip.birth_bonus) + " "),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(_vm.currencySymbol_))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bonus__description titile--text" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link--text",
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("change-tab", 1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("myCenter.birthdayBonus")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-1 py-1 caption", attrs: { cols: "4" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card1 rounded text-center d-flex flex-column align-center justify-center h-80px px-2",
                    },
                    [
                      _c("div", { staticClass: "bonus__times" }, [
                        _c("span", { staticClass: "secondary--text text-h6" }, [
                          _vm._v(" " + _vm._s(_vm.nowVip.monthly_bonus) + " "),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(_vm.currencySymbol_))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "bonus__description titile--text" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link--text",
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("change-tab", 1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("myCenter.monthlyBonus")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-0 pb-0", attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "divider" })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-1 rebates" },
            [
              _c(
                "v-col",
                { staticClass: "title--text mb-4", attrs: { cols: "12" } },
                [_vm._v(" " + _vm._s(_vm.$t("myCenter.vipTurnover")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-4 mt-0 pb-4" },
            _vm._l(_vm.nowVip.rebates, function (game) {
              return _c(
                "v-col",
                {
                  key: game.name,
                  staticClass: "px-1 py-1 caption",
                  attrs: { cols: "4" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card1 rounded d-flex flex-column align-center justify-center h-80px",
                    },
                    [
                      _c("div", { staticClass: "rebates__percentage" }, [
                        _c("span", { staticClass: "primary--text text-h6" }, [
                          _vm._v(" " + _vm._s(game.value) + "% "),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "rebates__description titile--text" },
                        [_vm._v(" " + _vm._s(game.name) + " ")]
                      ),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }