<template>
  <div
    id="vip-rules"
    class="pt-56"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="px-4 py-0"
        >
          <p
            class="title--text"
            v-html="vipRules"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: () => ({}),

  computed: {
    ...mapState({
      vipRules: state => state.websiteData.vip_rebate_statement,
      webName: state => state.websiteData.site_params.site_name,
    }),

    formatRules() {
      let copyStr = JSON.parse(JSON.stringify(this.vipRules))
      copyStr = copyStr.split('<li>')
      copyStr.shift()

      return copyStr.reduce((acc, cur) => {
        const titleNContent = cur.split('：')

        titleNContent[0] = titleNContent[0].includes('保留对活动的修改')
          ? `${this.webName} 保留对活动的修改，停止及最终解释权。`
          : titleNContent[0]

        return (acc = {
          ...acc,
          [titleNContent[0]]:
            '<li>' + titleNContent[1] === undefined
              ? '</li>'
              : titleNContent[1],
        })
      }, {})
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('myCenter.termsnRules'))
  },

  methods: {
    ...mapActions(['set_toolbar_title']),
  },
}
</script>

<style lang="scss" scoped>
li {
	list-style: none;
}

</style>
