var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-56 h-100dvh over-flow-y-scroll", attrs: { id: "vip" } },
    [
      _c(
        _vm.curryToolbar,
        { tag: "component" },
        [
          _c(
            "v-btn",
            {
              staticClass: "caption",
              class: [_vm.showTabTemplate ? "title--text" : "white--text"],
              attrs: { slot: "btn", to: "vip-rules", text: "" },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("myCenter.termsnRules")) + " ")]
          ),
        ],
        1
      ),
      _vm.showTabTemplate
        ? [
            _c(
              "v-tabs",
              {
                attrs: {
                  height: "40",
                  "background-color": "transparent",
                  color: "primary",
                  grow: "",
                },
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              _vm._l(_vm.tabs, function (tab, index) {
                return _c(
                  "v-tab",
                  { key: index, staticClass: "subtitle-1 font-weight-medium" },
                  [_vm._v(" " + _vm._s(tab) + " ")]
                )
              }),
              1
            ),
            _c("v-divider", { staticClass: "divider" }),
          ]
        : _vm._e(),
      _c(_vm.activeComponent, {
        tag: "component",
        attrs: { "vip-list": _vm.vipList },
        on: { "change-tab": _vm.changeTabActive },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }